<template>
  <v-menu
    transition="custom-tooltip"
    open-delay="150"
    bottom
    z-index="99"
    origin="top center"
    offset-overflow
    :close-on-content-click="false"
    open-on-hover
    content-class="v-tooltip"
    nudge-bottom="24px"
    :nudge-left="$vuetify.rtl ? '-200px' : '200px'"
    min-width="442px"
    max-width="442px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-1"
        x-small
        icon
        text
        color="error"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon color="error" size="20">$alertcircle </v-icon>
      </v-btn>
    </template>

    <div class="px-4 py-4">
      <p v-if="item.nsStatus == 'not-found'" class="mb-2">
        {{ $t('tooltip.hosting.domains.nameserversChecker.notResolved') }}:
      </p>
      <p v-else class="mb-2">
        {{ $t('tooltip.hosting.domains.nameserversChecker.notPointed') }}:
      </p>

      <ul>
        <nameserver-list-item
          v-for="(item, index) in nameservers"
          :key="item + index"
          :item="item"
          :index="index"
        />
      </ul>
    </div>
  </v-menu>
</template>

<script>
import NameserverListItem from "../NameserverListItem.vue";

export default {
  components: {
    NameserverListItem,
  },
  data: function () {
    return {
    };
  },
  props: {
    item: Object,
    nameservers: Array,
  },
};
</script>

<style>
</style>